import isEmpty from 'lodash/isEmpty';

import {
  ErrorMessage,
  I18nText,
  InviteUsersType,
  IsLoading
} from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';
import { UsersTabsType } from '../../../users/usersTypes';

import { useInviteUsersModalButton } from './hooks/useInviteUsersModalButton';

import { ItemIndexSidebarTeamItem } from '../../components/sidebars/ItemIndexSidebar/components/ItemIndexSidebarTeam';
import { InviteUsersModalBody } from './components/InviteUsersModalBody';

import { FormModalButton } from '../../../../helpers/buttons/FormModalButton';
import { AlertMessage } from '../../../../helpers/AlertMessage';
import { TooltipPlacement } from '../../../../helpers/tooltips/tooltipsConstants';

import { projectsKeys, tasksKeys, words } from '../../../../locales/keys';

const INVITE_USERS_FORM = 'invite-user-form';

interface InviteUsersModalButtonProps<InviteUsersResponse> {
  availableTabs?: UsersTabsType;
  errorMessage: ErrorMessage;
  isLoading: IsLoading;
  item: ItemIndexSidebarTeamItem;
  onClose?: () => void;
  onInviteUsers: ({
    uuid,
    userIds
  }: InviteUsersType) => Promise<InviteUsersResponse>;
  i18nText?: I18nText;
  icon?: IconsEnum;
  className?: string;
  iconClassName?: string;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
  scope: 'projects' | 'tasks';
}

function InviteUsersModalButton<InviteUsersResponse>({
  availableTabs,
  errorMessage,
  isLoading,
  item,
  onClose,
  onInviteUsers,
  i18nText,
  icon,
  className,
  iconClassName,
  tooltipI18nText,
  tooltipSingleton,
  tooltipPlacement,
  scope
}: InviteUsersModalButtonProps<InviteUsersResponse>) {
  const {
    handleDeselectAllUsers,
    handleDeselectUser,
    handleInviteUsers,
    handleSelectUser,
    selectedUserIds,
    selectedUsers
  } = useInviteUsersModalButton({ onInviteUsers, item });

  return (
    <FormModalButton
      className={
        className ||
        'py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full'
      }
      form={INVITE_USERS_FORM}
      i18nSubmitText={words.submit}
      i18nText={i18nText}
      i18nTitle={words.invite_user}
      icon={icon}
      iconClassName={iconClassName || 'h-9 w-9 p-2 mr-2'}
      isLoading={isLoading}
      onClose={onClose}
      onOpen={handleDeselectAllUsers}
      onSubmit={handleInviteUsers}
      submitDisabled={isEmpty(selectedUserIds)}
      tooltipSingleton={tooltipSingleton}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
    >
      <div className="flex-1 overflow-y-auto px-2">
        <div className="px-4">
          <InviteUsersModalBody
            availableTabs={availableTabs}
            errorMessage={errorMessage}
            invitedUserUuids={item.members?.map((member) => member.uuid)}
            selectedUsers={selectedUsers}
            selectedUserIds={selectedUserIds}
            onSelectUser={handleSelectUser}
            onDeselectUser={handleDeselectUser}
            alreadySelectedI18nTitle={
              scope === 'tasks'
                ? tasksKeys.alreadyMember
                : projectsKeys.alreadyMember
            }
          />
        </div>
      </div>
      <div className="px-4">
        <AlertMessage message={errorMessage} />
      </div>
    </FormModalButton>
  );
}

export default InviteUsersModalButton;
